<script lang="ts" setup>
import { useGetShopByPath } from '@/composables/openapi/shop/useGetShopByPath'

defineOptions({
  name: 'ShopContent',
})
const route = useRoute()
const shopSlug = computed(() => {
  return route.params.shop as string
})

const { shopByPath, suspenseGetShopByPath, getShopByPathIsError, getShopByPathHttpErrorStatus, getShopByPathIsSuccess } = useGetShopByPath(toRef(shopSlug))
await suspenseGetShopByPath()
const areaEntityId = toRef(shopByPath?.value?.areaEntityId)

const isAccountVerificationEnabled = computed(() => {
  return shopByPath.value?.isAccountVerification ?? false
})

provide('isAccountVerificationEnabled', isAccountVerificationEnabled)

watch(getShopByPathIsError, (isError) => {
  if (isError)
    throw new Error(getShopByPathHttpErrorStatus.value.toString())
}, { immediate: true })

watch(shopByPath, () => {
  if (shopByPath.value?.active === false)
    throw new Error(getShopByPathHttpErrorStatus.value.toString())
}, { immediate: true })

const error = ref()
const { t } = useI18n()

onErrorCaptured((err) => {
  error.value = err
  return false
})
</script>

<template>
  <div class="mx-auto w-full flex flex-col gap-8">
    <ShopContentHeadingSection v-if="getShopByPathIsSuccess" :name="shopByPath?.name ?? undefined" :heading-image="shopByPath?.coverImageUrl" />
    <div v-if="getShopByPathIsSuccess" class="flex flex-col gap-3 md:gap-5 md:pb-4">
      <span class="mb-0 w-full text-center text-2xl text-gray-900 font-600 md:text-4xl">{{ t('parking_tickets.title') }}</span>
      <span class="mt-0 w-full text-center text-gray-600 md:text-xl">{{ t('parking_tickets.subline') }}</span>
    </div>
    <div v-if="error">
      {{ t('no_tickets_found') }}
    </div>
    <Suspense v-else>
      <ShopContentTicketsSection :area-entity-id="areaEntityId" />
      <template #fallback>
        <div class="grid grid-cols-1 mx-auto justify-center gap-4 lg:grid-cols-2 lg:gap-8">
          <TicketCardLoading v-for="index in 4" :key="index" />
        </div>
      </template>
    </Suspense>
  </div>
</template>
