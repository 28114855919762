import type { Notification, ToastMessageType } from '@/types/components'
import { defineStore } from 'pinia'

export const notifications = defineStore('notifications', () => {
  const notifications = ref(new Map<number, Notification>())

  function close(timestamp: number) {
    notifications.value.delete(timestamp)
  }

  function add(type: ToastMessageType, message: string, details?: string, closeDelay = 15000) {
    const timestamp = new Date().getTime()
    notifications.value.set(timestamp, {
      type,
      message,
      closeDelay,
      details,
    })

    if (notifications.value.size > 5) {
      const oldestKey = notifications.value.keys().next().value
      if (oldestKey !== undefined) {
        notifications.value.delete(oldestKey)
      }
    }

    setTimeout(() => close(timestamp), closeDelay) // default 15 seconds
  }

  return { notifications, add, close }
})
