import type { FetchError } from 'ofetch'
import { auth0 } from '@/utils/auth0'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createHead } from '@unhead/vue'
import mixpanel from 'mixpanel-browser'

import { createPinia } from 'pinia'

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'

import DefaultLayout from './layouts/DefaultLayout.vue'

import EmptyLayout from './layouts/EmptyLayout.vue'
import NoFooterLayout from './layouts/NoFooterLayout.vue'

import router from './router'

import { datadogConfig, datadogRumConfig } from './utils/logger'
import 'virtual:uno.css'
import '@unocss/reset/tailwind-compat.css'
import '@/assets/styles/global.css'

const app = createApp(App)
const head = createHead()

if (import.meta.env.PROD) {
  datadogLogs.init(datadogConfig)
  datadogRum.init(datadogRumConfig)
  datadogRum.startSessionReplayRecording()
  mixpanel.init(import.meta.env.VITE_ONLINESHOP_MIXPANEL_TOKEN)
}

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: !(!import.meta.env.DEV || import.meta.env.VITE_BUILD_STAGE === 'staging'), // only refetch on window focus in production
        retry: (count: number, error: FetchError) => {
          if (error.response?.status === 404)
            return false // don't retry 404 errors
          return count <= 3 // we retry each query a maximum of 3 times
        },
        retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 5000), // double delay between each retry and never go above 5 seconds
      },
    },
  },
}

const i18n = createI18n({
  legacy: false,
  locale: navigator.language, // TODO: get from browser after we have other language as well
  fallbackLocale: {
    de: ['de-DE'],
    en: ['en-GB', 'en-US'],
    // it: ['it-IT', 'it-CH'],
    // fr: ['fr-FR', 'fr-CH'],
  },
  datetimeFormats: {
    en: {
      monthOnly: {
        month: 'long',
      },
    },
    de: {
      monthOnly: {
        month: 'long',
      },
    },
    // not supported yet
    // it: {
    //   monthOnly: {
    //     month: 'long',
    //   },
    // },
    // fr: {
    //   monthOnly: {
    //     month: 'long',
    //   },
    // },
  },
  messages,
})

app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(head)
app.use(auth0)
app.use(VueQueryPlugin, vueQueryPluginOptions)
app.component('DefaultLayout', DefaultLayout)
app.component('EmptyLayout', EmptyLayout)
app.component('NoFooterLayout', NoFooterLayout)

async function prepareMsw() {
  if (import.meta.env.MODE === 'mock') {
    const { worker } = await import('./mocks/browser')
    await worker.start()
  }
  else {
    return Promise.resolve()
  }
}

prepareMsw().then(() => {
  app.mount('#app')
})
