import type { ConfigurationParameters, ErrorContext, Middleware, RequestContext, ResponseContext } from '@/types/openapiRuntime'
import type { MaybeRef, ShallowRef } from 'vue'
import { authentication } from '@/stores/authentication'

import { useAuth0 } from '@auth0/auth0-vue'

interface Newable<T> { new (...args: any[]): T }

const developmentLoggingMiddleware: Middleware = {
  pre: async (context: RequestContext) => {
    if (import.meta.env.DEV)
      // eslint-disable-next-line no-console
      console.info('Middleware: Request Context', context)
  },
  post: async (context: ResponseContext) => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info('Middleware: Response Context', context)
    }
  },
  onError: async (context: ErrorContext) => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info('Middleware: Error Context', context)
    }
  },
}

export interface UseGenericOpenApiClientOptions<C, D> {
  Configuration: Newable<C>
  DefaultApi: Newable<D>
  configOverride?: MaybeRef<ConfigurationParameters>
  servicePath?: string
}

export function useGenericOpenApiClient<C, D>(
  options: UseGenericOpenApiClientOptions<C, D>,
): {
    configurationParameters: MaybeRef<ConfigurationParameters>
    configuration: ShallowRef<C>
    apiInstance: ShallowRef<D>
  } {
  const authenticationStore = authentication()
  const { getAccessTokenSilently } = useAuth0()

  const refreshTokenMiddleware: Middleware = {
    onError: async (context: ErrorContext) => {
      // if the error is a 401, we can refresh. Tanstack query will
      if (context.response?.status === 401) {
        // refresh token
        const newToken = await getAccessTokenSilently()
        authenticationStore.setAccessToken(newToken)
      }
    },
  }

  const middleware = computed((): Middleware[] => {
    return [
      refreshTokenMiddleware,
      import.meta.env.DEV ? developmentLoggingMiddleware : {},
      ...(unref(options.configOverride)?.middleware ?? []),
    ]
  })

  const basePath = options.servicePath ? `${import.meta.env.VITE_ONLINESHOP_TSBACKEND_BASE_URL}/${options.servicePath}` : import.meta.env.VITE_ONLINESHOP_TSBACKEND_BASE_URL

  const configurationParameters = computed((): ConfigurationParameters => {
    return {
      basePath,
      headers: {
        Authorization: `Bearer ${authenticationStore.accessToken}`,
      },
      middleware: unref(middleware),
      ...(unref(options.configOverride)),
    }
  })

  const configuration = shallowRef<C>(new options.Configuration(unref(configurationParameters)))
  const apiInstance = shallowRef<D>(new options.DefaultApi(unref(configuration)))

  function recreate() {
    configuration.value = new options.Configuration(unref(configurationParameters))
    apiInstance.value = new options.DefaultApi(unref(configuration))
  }

  onMounted(() => {
    recreate()
  })
  watch([configurationParameters], () => {
    recreate()
  })

  return {
    configurationParameters,
    configuration,
    apiInstance,
  }
}
