import { Configuration, DefaultApi } from '@peter-park/shop'
import { useGenericOpenApiClient, type UseGenericOpenApiClientOptions } from '../useGenericOpenApiClient'

const options: UseGenericOpenApiClientOptions<Configuration, DefaultApi> = {
  servicePath: 'shop',
  Configuration,
  DefaultApi,
}
export function useOpenApiClient() {
  return useGenericOpenApiClient<Configuration, DefaultApi>(options)
}
