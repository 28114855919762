import { userSettingsStore } from '@/stores'
import { getAppDefaultLanguage } from '@/utils/getAppDefaultLanguage'
import { useI18n } from 'vue-i18n'

export function useDefaultAppLanguage() {
  const { locale } = useI18n()
  const userSettings = userSettingsStore()
  // map the domain extension to the default language

  const defaultLanguageBasedOnDomainExtension = getAppDefaultLanguage()

  // if the userSelectedLanguage is present, set the i18n locale to that value
  if (userSettings.userSelectedLanguage) {
    locale.value = userSettings.userSelectedLanguage
  }
  // if the userSelectedLanguage is not there, but the domain extension is in the map, set the i18n locale to that value
  else if (defaultLanguageBasedOnDomainExtension) {
    locale.value = defaultLanguageBasedOnDomainExtension
    userSettings.setLanguage(defaultLanguageBasedOnDomainExtension)
  }
  // otherwise, set the i18n locale to the navigator language
  // this will trigger the checks in the utility library and set the language to the default one if the navigator language is not supported
  else {
    userSettings.setLanguage(navigator.language)
  }
  return {
    defaultLanguageBasedOnDomainExtension,
  }
}
