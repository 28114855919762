import type { Context } from '@datadog/browser-core'
import type { LogsInitConfiguration, StatusType } from '@datadog/browser-logs'
import type { RumInitConfiguration } from '@datadog/browser-rum'
import type { VerifiedPurchaseOptionResponse } from '@peter-park/available-purchase-option'

import type { OrderCreateResponseDto } from '@peter-park/payment-hub'
import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/shop'
import type { LocationQuery } from 'vue-router'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { logEvent as _logEvent } from '@peter-park/vue-common-utils'
import mixpanel from 'mixpanel-browser'

export const datadogConfig: LogsInitConfiguration = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  sessionSampleRate: 100,
  service: 'online-shop',
  version: $APP_VERSION,
  env: import.meta.env.VITE_BUILD_STAGE,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
}
export const datadogRumConfig: RumInitConfiguration = {
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  sessionSampleRate: 100,
  service: 'online-shop',
  version: $APP_VERSION,
  env: import.meta.env.VITE_BUILD_STAGE,
  site: 'datadoghq.eu',
  applicationId: import.meta.env.VITE_ONLINESHOP_DD_APPLICATION_ID,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
}

export interface LoggerSuperProperties extends Context {
  shop_slug: string
  user_id: string
  user_nickname: string
}
export function setSuperProperties(superProperties: LoggerSuperProperties) {
  if (import.meta.env.PROD) {
    mixpanel.register(superProperties)
    datadogRum.setUser({
      id: superProperties.user_id.toString(),
      name: superProperties.user_nickname,
    })
    datadogRum.onReady(() => {
      datadogRum.setGlobalContext(superProperties)
    })
    datadogLogs.onReady(() => {
      datadogLogs.setGlobalContext(superProperties)
    })
  }
  else {
    // eslint-disable-next-line no-console
    console.log('setSuperProperties', superProperties)
  }
}

/**
 * Internal enum with messages to log for each event
 */
enum LoggerEventsMessages {
  initApp = 'init Application',
  changeLanguage = 'Change Language',
  logout = 'Logout',
  login = 'Login',
  signUp = 'Sign Up',
  selectTicket = 'Select Ticket',
  buyTicket = 'Press Buy Ticket Button',
  configureTicket = 'Configure Ticket',
  configureTicketError = 'Configure Ticket Error',
  buyTicketSuccess = 'Buy Ticket Success',
  buyTicketFailed = 'Buy Ticket Failed',
  verifyCart = 'Verify Cart',
  vertifyEmptyCart = 'Verify Empty Cart',
  verifyCartSuccess = 'Verify Cart Success',
  verifyCartFailed = 'Verify Cart Failed',
  createOrder = 'Create Order',
  orderCreated = 'Order Created',
  pageNotFound = 'Page Not Found',
  getShopByPathError = 'Could not get shop info',
  verifyUser = 'Verify User',
  verifyUserError = 'Verify User Error',
  verifyUserSuccess = 'Verify User Success',
  addVerificationButtonClicked = 'Add Verification Button Clicked',
  loadUserVerificationCodesError = 'Load Verification Codes Error',
  mvtAlreadyBoughtFromDifferentUser = 'MVT already bought for the same plate by another user',
}

export function logEvent(message: LoggerEventsMessages, messageContext?: Record<string, unknown>, statusType?: StatusType, error?: Error) {
  if (import.meta.env.PROD)
    _logEvent(message, messageContext, statusType, error)

  else
    // eslint-disable-next-line no-console
    console.info(message, messageContext, statusType, error)
}

export function logInitApp(locationQuery: LocationQuery, status?: StatusType, error?: Error) {
  logEvent(LoggerEventsMessages.initApp, locationQuery, status, error)
}

export function logChangeLanguage(language: string) {
  logEvent(LoggerEventsMessages.changeLanguage, { language })
}
export function logLogout() {
  logEvent(LoggerEventsMessages.logout, {})
}
export function logLogin() {
  logEvent(LoggerEventsMessages.login, {})
}
export function logSignUp() {
  logEvent(LoggerEventsMessages.signUp, {})
}
export function logSelectTicket(ticketId: string) {
  logEvent(LoggerEventsMessages.selectTicket, { ticketId })
}
export function logBuyTicket(ticketId: string) {
  logEvent(LoggerEventsMessages.buyTicket, { ticketId })
}
export function logConfigureTicket(ticketId: string) {
  logEvent(LoggerEventsMessages.configureTicket, { ticketId })
}
export function logConfigureTicketError(ticketId: string, error: Error | null) {
  logEvent(LoggerEventsMessages.configureTicketError, { ticketId, error }, 'error')
}
export function logVerifyCart(shopId: string, tickets: AvailablePurchaseOptionFixedPriceTicketDto[]) {
  logEvent(LoggerEventsMessages.configureTicket, { shopId, tickets })
}
export function logVerifyEmptyCart(shopId: string) {
  logEvent(LoggerEventsMessages.vertifyEmptyCart, { shopId })
}
export function logVerifyCartSuccess(shopId: string, tickets: VerifiedPurchaseOptionResponse[]) {
  logEvent(LoggerEventsMessages.verifyCartSuccess, { shopId, tickets })
}
export function logVerifyCartFailed(shopId: string, tickets: VerifiedPurchaseOptionResponse[]) {
  logEvent(LoggerEventsMessages.verifyCartFailed, { shopId, tickets }, 'error')
}
export function logCreateOrder(shopId: string, tickets: VerifiedPurchaseOptionResponse[]) {
  logEvent(LoggerEventsMessages.createOrder, { shopId, tickets })
}
export function logOrderCreated(shopId: string, orderDetails: OrderCreateResponseDto) {
  logEvent(LoggerEventsMessages.orderCreated, { shopId, orderDetails })
}
export function logBuyTicketSuccess() {
  logEvent(LoggerEventsMessages.buyTicketSuccess, {})
}
export function logBuyTicketFailed() {
  logEvent(LoggerEventsMessages.buyTicketFailed, {}, 'error')
}
export function logPageNotFound(pageRequired: string, referrer: string = '') {
  logEvent(LoggerEventsMessages.pageNotFound, { pageRequired, referrer }, 'warn')
}
export function logVerifyUser(code: string) {
  logEvent(LoggerEventsMessages.verifyUser, { code })
}
export function logVerifyUserError(error: Error | null) {
  logEvent(LoggerEventsMessages.verifyUserError, { error }, 'warn')
}
export function logVerifyUserSuccess() {
  logEvent(LoggerEventsMessages.verifyUserSuccess, {})
}
export function logAddVerificationButtonClicked() {
  logEvent(LoggerEventsMessages.addVerificationButtonClicked, {})
}
export function logLoadUserVerificationCodesError(error: Error | null) {
  logEvent(LoggerEventsMessages.loadUserVerificationCodesError, { error }, 'error')
}
export function logGetShopByPathError(error: Error | null) {
  logEvent(LoggerEventsMessages.getShopByPathError, { error }, 'error')
}
export function logMvtAlreadyBoughtFromDifferentUser(userId?: string, plates?: { plate: string, country: string }[]) {
  logEvent(LoggerEventsMessages.mvtAlreadyBoughtFromDifferentUser, { userId, plates }, 'warn')
}
