<script lang="ts" setup>
import { useDefaultAppLanguage } from '@/composables/useDefaultAppLanguage'
import { notifications } from '@/stores'
import { logInitApp } from '@/utils/logger'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

const route = useRoute()
const layout = computed(() => {
  return route.meta.layout || 'DefaultLayout'
})

const notificationsStore = notifications()

useHead({
  title: () => route.meta.title ?? '',
  meta: [
    {
      name: 'description',
      content: () => route.meta.description,
    },
  ],
})

useDefaultAppLanguage()
logInitApp(route.query)
</script>

<template>
  <component :is="layout">
    <router-view />
  </component>
  <UiToastNotification
    :notifications="notificationsStore.notifications"
    @close="notificationsStore.close"
  />
  <VueQueryDevtools />
</template>
