<script lang="ts" setup>
import type { Notification, ToastMessageType } from '@/types'
import { useNow } from '@vueuse/core'

defineOptions({
  name: 'ToastNotification',
})
const props = withDefaults(defineProps<{
  notifications: Map<number, Notification>
  to?: string
}>(), {
  to: 'body',
})
const emits = defineEmits<{
  close: [value: number]
}>()

const typeIconClassMap = new Map<ToastMessageType, string>([
  ['success', 'text-success-500 i-ph:check-circle'],
  ['error', 'text-error-500 i-ph:x-circle'],
  ['info', 'text-primary-500 i-ph:info'],
  ['warning', 'text-warning-500 i-ph:warning-circle'],
])

const typeLoaderBackgroundMap = new Map<ToastMessageType, string>([
  ['success', 'bg-success-500'],
  ['error', 'bg-error-500'],
  ['info', 'bg-primary-500'],
  ['warning', 'bg-warning-500'],
])

const now = useNow()
const currentTimeStamp = computed(() => {
  return unref(now).getTime()
})
</script>

<template>
  <Teleport :to="props.to">
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed bottom-0 z-300 w-full flex flex-col items-end justify-end gap-4 p-3 sm:justify-start">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition-group
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-x-20 opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="transition ease-in duration-300"
        leave-from-class="opacity-100 translate-x-0"
        leave-to-class="opacity-0 translate-x-20"
      >
        <div
          v-for="[timestamp, notification] in props.notifications.entries()"
          :key="timestamp"
          class="pointer-events-auto max-w-sm w-full overflow-hidden rounded-xl bg-gray-50 shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-3">
            <div class="flex items-start">
              <div class="my-auto flex-shrink-0">
                <div
                  class="my-auto h-8 w-8"
                  :class="typeIconClassMap.get(notification.type)"
                  aria-hidden="true"
                />
              </div>
              <div class="my-auto ml-3 w-0 flex-1">
                <p class="text-sm text-gray-500">
                  {{ notification.message }}
                </p>
                <p class="text-sm text-bluegray-200">
                  {{ notification.details }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" class="inline-flex rounded-md border-none bg-gray-50 text-gray-500 focus:text-gray-600 hover:text-gray-600" @click="emits('close', timestamp)">
                  <div class="i-heroicons:x-mark h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div
            class="h-0.5 rounded"
            :class="[typeLoaderBackgroundMap.get(notification.type)]"
            :style="`width:${(100 - ((currentTimeStamp - timestamp) / (notification.closeDelay)) * 100).toFixed(3)}%`"
          />
        </div>
      </transition-group>
    </div>
  </Teleport>
</template>
