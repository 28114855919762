import type { MaybeRef } from '@vueuse/core'
import { logLogin, logLogout, logSignUp } from '@/utils/logger'
import { useAuth0 } from '@auth0/auth0-vue'

export function useShopAuth(shop: MaybeRef<string>) {
  const { error, isAuthenticated, user, loginWithRedirect, logout, checkSession } = useAuth0()

  // getters for values in the user object
  const metaLatestShopVisited = computed<string | undefined>(() => {
    return unref(user)?.last_shop_visited
  })

  const emailVerified = computed(() => {
    return unref(user)?.email_verified
  })

  const userEmail = computed(() => {
    return unref(user)?.email
  })

  const userId = computed(() => {
    return unref(user)?.sub ?? 'not-logged-in'
  })

  const nickname = computed(() => {
    return unref(user)?.nickname ?? 'not-logged-in'
  })

  // helper functions
  function handleLogout() {
    const origin = window.location.origin
    logLogout()
    logout({
      logoutParams: {
        returnTo: `${origin}/callback-logout`,
      },
    })
  }

  function handleLogin() {
    logLogin()
    loginWithRedirect({
      appState: {
        target: `/${unref(shop)}`,
      },
      authorizationParams: {
        lastShopVisited: unref(shop),
        ui_locales: 'de en',
      },
    })
  }

  function handleSignup() {
    logSignUp()
    loginWithRedirect({
      appState: {
        target: `/${unref(shop)}`,
      },
      authorizationParams: {
        screen_hint: 'signup',
        lastShopVisited: unref(shop),
        ui_locales: 'de en',
      },
    })
  }

  return {
    checkSession,
    emailVerified,
    userEmail,
    userId,
    nickname,
    error,
    handleLogin,
    handleLogout,
    handleSignup,
    isAuthenticated,
    metaLatestShopVisited,
    user,
  }
}
